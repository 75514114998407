@import "src/styles/variables";

.question-number-container {
	background-color: $white-color;
	color: $dark-white;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	margin: auto;
	p {
		font-weight: bold;
		margin: 0;
		text-align: center;
		padding: 10px 0px;
	}
}

.question-number__completed {
	background-color: $medium-grey;
	color: $dark-white;
}

.question-number__current {
	background-color: $ternary-color;
	color: $white-color;
}

.question-number__flag {
    text-align: center;
	.question-flagged {
        color: red;
        font-size: 50%;
        margin: auto;
	}
	p {
		padding: 0px !important;
		margin-top: -7px;
	}
}
