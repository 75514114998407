.show-blog__container {
	padding: 3% 5%;

	.article-container {
		display: grid;
		grid-template-columns: 3fr 1fr;
		grid-column-gap: 3%;

		.current-article {
			.title {
				margin: auto 0;
			}
		}
	}

	.popular-food {
		margin-top: 4%;
		.title {
		}

		.card-wrapper {
			margin-top: 2%;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-column-gap: 3%;
		}
	}

	.next-articles {
		.title {
		}

		.card-wrapper {
		}
	}
}

@media only screen and (max-width: 780px) {
	.show-blog__container {
		.article-container {
			grid-template-columns: 1fr;
		}
		.next-articles {
			margin-top: 4%;
		}
		.popular-food {
			.card-wrapper {
				grid-template-columns: 1fr;
			}
		}
	}
}
