.footer-container {
  background-color: #3b3d48;
  color: white;
  .about-us {
    padding: 2% 5%;
    .title {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      .follow-us {
        text-align: center;
      }

      p {
        font-weight: bolder;
      }
    }

    .description {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      .desc-wrapper {
        .desc-item {
          opacity: 0.8;
          transform: matrix(1, 0, 0, 1, 0, 0);
          color: white;

          &:hover {
            opacity: 1;
          }
        }
        .social-media {
          margin: auto 0;
          width: 70%;
          display: flex;
          justify-content: space-around;
          margin-top: 8%;
          img {
            opacity: 0.7;
            cursor: pointer;
          }
        }
      }
    }
  }
  .line {
    width: 100%;
    height: 3px;

    background: rgba(255, 255, 255, 0.05);
    border-radius: 1px;
    transform: matrix(1, 0, 0, -1, 0, 0);
  }

  .copy-right {
    padding: 2% 12%;
    opacity: 0.6;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}

@media only screen and (max-width: 768px) {
  .footer-container {
    .about-us {
      .description {
        grid-template-columns: 1fr !important;
        div {
          text-align: center;
          margin-top: 5%;

          .social-media {
            margin: auto !important;
          }

          .title-text {
            font-size: 24px !important;
          }

          .desc-wrapper {
            a {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

.wraper-footer {
    .container {
        width: 90%;
        margin: auto;

        .copy-rights {
            width: 83%;
            margin: auto;
        }
    }
}


.footer-main {
  display: flex;
  justify-content: space-between;
  padding: 18px;
  .col-md-12 {
    width: 60%;
    .row {
      display: flex;
      justify-content: space-between;
      .menu {
        padding: 0px;
      }
    }
  }

  .col-md-6 {
    width: 30%;
  }
}

/* ====================== */
/* START OF FOOTER */
/* ====================== */
/* wraper-footer */
.wraper-footer {
  background-color: #fff;
  position: relative;
}
.wraper-footer .footer-dot {
  width: 198px;
  height: 191px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.wraper-footer .footer-dot img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* wraper-footer-cta */
.wraper-footer-cta {
  text-align: center; /* footer-cta */
}
.wraper-footer-cta > .container {
  padding-top: 40px;
  padding-bottom: 0;
}
@media screen and (max-width: 991px) {
  .wraper-footer-cta > .container {
    padding-top: 10px;
  }
}
@media screen and (max-width: 575px) {
  .wraper-footer-cta > .container {
    padding-top: 0px;
  }
}
.wraper-footer-cta .footer-cta {
  padding-bottom: 50px; /* footer-cta-data */
}
@media screen and (max-width: 991px) {
  .wraper-footer-cta .footer-cta {
    padding-top: 20px;
  }
}
.wraper-footer-cta .footer-cta .footer-cta-data > *:last-child {
  margin-bottom: 0 !important;
}
.wraper-footer-cta .footer-cta .footer-cta-data .copy-rights {
  color: #676767;
  font-family: "Nunito", sans-serif;
  font-size: 15px;
  opacity: 0.5;
  line-height: 1.53;
}
@media screen and (min-width: 1800px) {
  .wraper-footer-cta .footer-cta .footer-cta-data .copy-rights {
    font-size: 18px;
  }
}

/* wraper-footer-main */
.wraper-footer-main {
  /* footer-main */
}
.wraper-footer-main > .container {
  padding-top: 70px;
  padding-bottom: 20px;
}
@media screen and (max-width: 991px) {
  .wraper-footer-main > .container {
    padding-top: 50px;
    padding-bottom: 0px;
  }
}
.wraper-footer-main .footer-main {
  /* footer-main-item */
}
.wraper-footer-main .footer-main .footer-main-item {
  margin-bottom: 0px; /* widget-title */ /* img */ /* p */ /* ul.menu */ /* footer-social */
}
@media screen and (max-width: 991px) {
  .wraper-footer-main .footer-main .footer-main-item {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 767px) {
  .wraper-footer-main .footer-main .footer-main-item {
    text-align: center;
  }
}
.wraper-footer-main .footer-main .footer-main-item > *:last-child {
  margin-bottom: 0 !important;
}
.wraper-footer-main .footer-main .footer-main-item .widget-title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
  font-family: "Nunito", sans-serif;
  color: #000000;
}
@media screen and (min-width: 1800px) {
  .wraper-footer-main .footer-main .footer-main-item .widget-title {
    font-size: 24px;
  }
}
@media screen and (max-width: 991px) {
  .wraper-footer-main .footer-main .footer-main-item .widget-title {
    margin-bottom: 10px;
  }
}
.wraper-footer-main .footer-main .footer-main-item img {
  max-width: 150px;
  margin-bottom: 0;
}
.wraper-footer-main .footer-main .footer-main-item p {
  margin-bottom: 23px;
  font-weight: 500;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.6);
}
@media screen and (min-width: 1800px) {
  .wraper-footer-main .footer-main .footer-main-item p {
    font-size: 18px;
  }
}
.wraper-footer-main .footer-main .footer-main-item p a {
  color: inherit;
}
.wraper-footer-main .footer-main .footer-main-item p strong {
  font-weight: 700;
}
.wraper-footer-main .footer-main .footer-main-item p.brand-logo {
  margin-bottom: 40px;
}
.wraper-footer-main .footer-main .footer-main-item p.copyright-note {
  font-size: 12px;
}
.wraper-footer-main .footer-main .footer-main-item ul.menu {
  list-style-type: none;
}
.wraper-footer-main .footer-main .footer-main-item ul.menu > li {
  margin-bottom: 20px;
}
@media screen and (max-width: 991px) {
  .wraper-footer-main .footer-main .footer-main-item ul.menu > li {
    margin-bottom: 15px;
  }
}
.wraper-footer-main .footer-main .footer-main-item ul.menu > li:last-child,
.wraper-footer-main .footer-main .footer-main-item ul.menu > li:only-child {
  margin-bottom: 0;
}
.wraper-footer-main .footer-main .footer-main-item ul.menu > li > a {
  font-family: "Nunito", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #676767;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media screen and (min-width: 1800px) {
  .wraper-footer-main .footer-main .footer-main-item ul.menu > li > a {
    font-size: 18px;
  }
}
.wraper-footer-main .footer-main .footer-main-item ul.menu > li > a:hover,
.wraper-footer-main
  .footer-main
  .footer-main-item
  ul.menu
  > li.current-menu-item
  > a {
  color: #a9070b;
  text-decoration: none;
}
.wraper-footer-main .footer-main .footer-main-item ul.menu > li strong {
  font-weight: 700;
}
.wraper-footer-main .footer-main .footer-main-item .footer-newsletter-form {
  margin-bottom: 30px;
}
.wraper-footer-main
  .footer-main
  .footer-main-item
  .footer-newsletter-form
  .newsletter-form-group {
  width: 100%;
  height: 52px;
  border-radius: 4px;
  border: solid 1px #d4cbcb;
  background-color: #ffffff;
  padding-right: 35px;
  position: relative;
}
.wraper-footer-main
  .footer-main
  .footer-main-item
  .footer-newsletter-form
  .newsletter-form-group
  input {
  font-size: 12px;
  font-weight: 500;
  background-color: transparent;
  padding: 10px 15px;
  width: 100%;
  height: 100%;
  border: none;
}
.wraper-footer-main
  .footer-main
  .footer-main-item
  .footer-newsletter-form
  .newsletter-form-group
  input:focus {
  border: none;
  box-shadow: none;
}
.wraper-footer-main
  .footer-main
  .footer-main-item
  .footer-newsletter-form
  .newsletter-form-group
  .btn-send {
  position: absolute;
  right: 5px;
  color: #b4b4b4;
  font-size: 12px;
  top: 15px;
  border: none;
  background-color: transparent;
}
.wraper-footer-main
  .footer-main
  .footer-main-item
  .footer-newsletter-form
  .newsletter-form-group
  .btn-send:hover {
  color: #a9070b;
}
.wraper-footer-main .copy-right-row {
  margin-top: 40px;
}
@media screen and (max-width: 991px) {
  .wraper-footer-main .copy-right-row {
    margin-top: 0;
  }
}
.wraper-footer-main .copy-right-row .logoText {
  font-family: "Nunito", sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.35;
  color: #d02c27;
  text-transform: uppercase;
}
@media screen and (min-width: 1800px) {
  .wraper-footer-main .copy-right-row .logoText {
    font-size: 22px;
  }
}
@media screen and (max-width: 767px) {
  .wraper-footer-main .copy-right-row .logoText {
    display: block;
    text-align: center;
  }
}
.wraper-footer-main .copy-right-row .copyRightText {
  opacity: 0.6;
  font-family: "Nunito", sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 0.81;
  letter-spacing: normal;
  color: #676767;
  padding-left: 20px;
  margin-left: 20px;
  border-left: 1px solid #676767;
}
@media screen and (min-width: 1800px) {
  .wraper-footer-main .copy-right-row .copyRightText {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .wraper-footer-main .copy-right-row .copyRightText {
    display: block;
    border-left: none;
    padding-left: 0;
    margin-left: 0;
    margin-top: 10px;
    text-align: center;
  }
}
.wraper-footer-main .footer-social-holder {
  max-width: 100%;
  text-align: left;
}
@media screen and (max-width: 767px) {
  .wraper-footer-main .footer-social-holder {
    text-align: center;
  }
}
.wraper-footer-main .footer-social-holder .widget-title {
  margin-bottom: 20px;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.3;
  font-family: "Nunito", sans-serif;
  color: #000000;
}
@media screen and (min-width: 1800px) {
  .wraper-footer-main .footer-social-holder .widget-title {
    font-size: 24px;
  }
}
@media screen and (max-width: 991px) {
  .wraper-footer-main .footer-social-holder .widget-title {
    margin-bottom: 10px;
  }
}
.wraper-footer-main .footer-social-holder ul.footer-social {
  margin-bottom: 0;
  font-size: 0;
}
.wraper-footer-main .footer-social-holder ul.footer-social > li {
  display: inline-block;
  margin-bottom: 0;
}
@media screen and (max-width: 575px) {
  .wraper-footer-main .footer-social-holder ul.footer-social > li {
    display: inline-block;
    margin-right: 10px;
  }
}
.wraper-footer-main .footer-social-holder ul.footer-social > li > a {
  transition: all ease-in-out 300ms;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto;
  color: #676767;
  padding-right: 0;
  margin-right: 15px;
  width: 48px;
  height: 48px;
  text-align: center;
  background-color: #efefef;
}
@media screen and (min-width: 1800px) {
  .wraper-footer-main .footer-social-holder ul.footer-social > li > a {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .wraper-footer-main .footer-social-holder ul.footer-social > li > a {
    padding-right: 5px;
    margin-right: 5px;
    padding-left: 5px;
    margin-left: 5px;
  }
}
.wraper-footer-main .footer-social-holder ul.footer-social > li > a:hover {
  background-color: #dbdbdb;
}
.wraper-footer-main
  .footer-social-holder
  ul.footer-social
  > li.facebook
  a:hover {
  color: #4267b2;
}
.wraper-footer-main
  .footer-social-holder
  ul.footer-social
  > li.twitter
  a:hover {
  color: #1da1f2;
}
.wraper-footer-main .footer-social-holder ul.footer-social > li.instagram a {
  font-size: 27px;
  position: relative;
  top: 4px;
}
.wraper-footer-main
  .footer-social-holder
  ul.footer-social
  > li.instagram
  a:hover {
  color: #c13584;
}
.wraper-footer-main
  .footer-social-holder
  ul.footer-social
  > li.youtube
  a:hover {
  color: #ff0000;
}
.wraper-footer-main
  .footer-social-holder
  ul.footer-social
  > li.linkedin
  a:hover {
  color: #0e76a8;
}

/* ====================== */
/* END OF FOOTER */
/* ====================== */
