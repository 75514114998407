.results__container {
	.no-border {
		border: none !important;
	}
	.result__container {
		cursor: pointer;
		display: grid;
		grid-template-columns: 0.5fr 5fr 0.5fr;
		text-align: center;
		padding: 3% 1%;
		border-bottom: 1px solid #eaedf2;
		p {
			margin: auto 0;
			text-align: left;
			color: #494a50;
		}

		.result-icon {
			margin: auto;
			font-size: 20px;
		}

		.checkout-icon {
			color: #494a50;
			font-weight: lighter;
		}
		.result__view__more {
		}
	}

	&:last-child {
		border: none !important;
	}
}

@media only screen and (max-width: 768px) {
	.result__container {
		p {
			padding: 5%;
		}
	}
}
