@import "src/styles/variables";

.reveal__container {
    background-color: $white-color;
    padding: 3%;
    .reveal__header {
        text-align: center;
        font-weight: bold;
    }
	.ui.secondary.menu {
		justify-content: center;
		.active.item {
			border: 1px solid $dark-white;
			color: $ternary-color !important;
			box-sizing: border-box;
			background: $white-color;
		}
	}
}

.result-icon {
    font-size: 20px !important;
}