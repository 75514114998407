@import "src/styles/variables";

.topic-redirect {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 5rem;
    box-shadow: $primary-shadow;
	width: calc(100vw - 180px);
    float: right;
    .topic-top {
        // border: 1px solid #efe;
        width: 100%;
        background-color: $white-color;
        margin: auto;
        display: flex;
        justify-content: space-between;
        padding: 0.7rem 2rem;
        p {
            margin: auto 0;
        }
	}
}


.test-model {
	.primary.button {
		min-width: 85px !important;
		max-height: 37px !important;
		margin: auto 5px !important;
	}
}

.react-pdf__Page__canvas {
	width: 100% !important;
}
.pdf-view {
	margin-top: 25px;
	padding: 1rem 2%;
	
	.options {
		width: 100%;
		display: flex;
		justify-content: space-between;
		i {
			font-size: 18px;
		}
		.download {
			display: flex;
			cursor: pointer;

			a {
				color: $ternary-color;
				font-weight: bolder;
				font-size: 16px;
			}
		}
	}
	.view-pdf {
		margin: auto;
		margin-top: 40px;

		.viewer-layout-container {
			width: 100%;

			.viewer-layout-main {
				height: 85vh;
			}
		}

		canvas {
			width: 100%;
		}
	}
}

.react-pdf__Page__textContent {
	width: 100%;
}

.viewer-tooltip-body-content {
	display: none !important;
}

@media only screen and (max-width: 768px) {



	.pdf {
		width: 90vw !important;

	}
	canvas {
		width: 90vw !important;

	}

	.react-pdf__Page__textContent {
		width: 90vw !important;
	}
	.topic-redirect {
		grid-template-columns: 1fr !important;
		width: 100% !important;
	}

	.application-body {
		width: 100% !important;
		padding: 1rem;
	}
}

.no-pdf-found {
	p {
		text-align: center;
	}
}

.viewer-canvas-layer{
	canvas{
	   transform: scale(1) !important;
	}
 }

 .viewer-layout-main {
	 user-select: none;
 }