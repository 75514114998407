@import "src/styles/variables";

.list-topic__container {
	margin-top: 40px;
	.topic-header {
		margin-top: 1.5rem;

		p {
			font-weight: bold;
			font-size: 12px;
			margin: auto 0px;
			color: $primary-color;
		}

		h2 {
			margin: 0.5rem 0px auto;
			font-size: 20px;
		}
	}
	.list-topics {
		margin-top: 40px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 10px;
	}
}

@media only screen and (max-width: 768px) {
	.list-topic__container {
        .list-topics {
            grid-template-columns: 1fr !important;
        }
	}
}
