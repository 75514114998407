@import "src/styles/variables";

.percentage__container {
	h1 {
		margin: auto;
		.percentage__perc {
			font-size: 16px;
			font-weight: lighter;
		}
	}
}

@media only screen and (max-width: 768px) {
	.percentage__container {
		h1 {
            font-size: 24px !important;
            
		.percentage__perc {
            font-size: 12px !important;
        }
		}
	}
}
