@import "src/styles/variables";

.dashboard {
    margin-top: 100px;

    .dashboard-inner__container {
        .dashboard-header {
            p {
                color: $primary-color;
                font-size: 13px;
                margin: auto 0;
            }
        }
    }
}


