@import "src/styles/variables";

.question-palette-option__container {
	display: flex;
	padding: 15px 20px;
	border-top: 1px solid #eaedf2;
	cursor: pointer;
	.display-content {
		width: 100%;
		display: flex;
		min-height: 40px;
		.question-palette-option {
			margin: auto 0;
			border: 1px solid $light-grey;
			box-sizing: border-box;
			border-radius: 4px;
			// width: 25px;
			// height: 25px;
			text-align: center;
			margin-right: 4%;

			span {
				margin: auto;
				padding: 5px 6px;
			}
			p {
				margin: auto 0 !important;
			}
		}
		p {
			margin: auto 0 !important;
		}
	}
	.answer-status {
		margin: auto;
		i {
			font-size: 1.5rem;
		}
	}
}
.result-answer__case__left {
	.question-palette-option {
		margin-right: 0px !important;
	}
}
.result-answer__case__left {
	.display-content {
		min-height: 50px !important;
	}
}
.question-palette-option-selected {
	// background-color: #F7F8FA;
	background-color: rgb(198, 198, 202);
}

.text-strikethrougn {
	p {
		text-decoration: line-through;
		color: #d3dbe2;
	}

	.question-palette-option {
		border: 1px solid #d3dbe2;
		span {
			color: #d3dbe2;
		}
	}
}

.disable-option {
	pointer-events: none;
}
