@import "variables";

/* Helpers CSS */

.d-none {
  display: none !important;
}

.float-right {
  float: right !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-bold {
  font-weight: bold;
}

.text-light-grey {
  color: $light-grey;
}

.text-dark-grey {
  color: $dark-grey;
}

.text-primary {
  color: $primary-color;
}

.text-danger {
  color: $danger-color;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.float-none {
  float: none !important;
}

.primary-black {
  color: $primary-black !important;
}

.cursor-pointer {
  cursor: pointer;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.w-100 {
  width: 100% !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.mt-1 {
  margin-top: ($spacer-y * .25) !important;
}

.mt-2 {
  margin-top: ($spacer-y * .5) !important;
}

.mt-3 {
  margin-top: ($spacer-y * .75) !important;
}

.mt-4 {
  margin-top: ($spacer-y * 1) !important;
}

.mt-5 {
  margin-top: ($spacer-y * 1.25) !important;
}

.mb-2 {
  margin-bottom: ($spacer-y * .5) !important;
}

.mb-3 {
  margin-bottom: ($spacer-y * .75) !important;
}

.mb-4 {
  margin-bottom: ($spacer-y * 1) !important;
}

.mb-5 {
  margin-bottom: ($spacer-y * 1.25) !important;
}


.ml-2 {
  margin-left: ($spacer-x * .5) !important;
}

.mr-2 {
  margin-right: ($spacer-x * .5) !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.m-auto {
  margin: auto !important;
}
