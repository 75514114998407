@import "src/styles/variables";

.profile-container {
	margin: 7% auto 0;

	.edit-input__container {
		display: flex;
		justify-content: center;
		input {
			width: 250px;
			&:first-child {
				margin-right: 10px;
			}
			&:last-child {
				margin-right: 10px;
			}
		}
		.error {
			font-size: 14px;
			text-align: left;
			color: $danger-color;
		}
	}

	.edit-input__wrapper__address {
        margin: 0px !important;
        input {
            width: 100%;
        }
		.error {
			font-size: 14px;
			text-align: left;
			color: $danger-color;
		}
	}
	.edit-input {
		border: 1px solid #999ea0 !important;
		border-radius: 4px;
		text-indent: 10px;
	}
	.user-wrapper {
		text-align: center;

		h1 {
			color: $ternary-color;
		}

		p {
			color: #999ea0;
			font-size: 18px;
		}

		.btn {
			width: 20%;
			display: flex;
			justify-content: space-between;
			margin: auto;
			.ui-custom-btn__container {
				cursor: pointer;
				p {
					cursor: pointer;
				}
			}
		}

		.btn-center {
			justify-content: center;
			button {
				cursor: pointer;
			}
		}
	}
	.user-course-info {
		margin: 5% auto 0;
		width: 60%;
		.info {
			display: grid;
			grid-template-columns: 1fr 5fr;
			border-top: 1px solid #eaedf2;
			border-bottom: 1px solid #eaedf2;
			padding: 3% 0;
			p {
				margin: auto 0;
			}
			.title {
				color: $ternary-color !important;
				font-size: 18px;
			}
			.content {
				color: #999ea0;
				font-size: 18px;
			}

			div {
				display: inline-block;
				margin: auto 0 auto auto;
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.profile-container {
		margin-top: 70px;
		// margin: 0px;

		.user-wrapper {
			.btn {
				width: 70% !important;
			}
		}

		.user-course-info {
			width: 90% !important;

			.info {
				grid-template-columns: 1fr 3fr !important;
			}
		}
	}
}
