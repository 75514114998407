@import "src/styles/variables";

.module-container {
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr;
    background-color: $white-color;
    box-shadow: 0px 8px 10px #f0f2f5;
    border-radius: 6px;
    padding: 5% 2%;
    width: 100%;
    margin: auto;
    height: 100%;
    width: 100%;
    cursor: pointer;
    &:hover {
        transition: all 0.2s ease-out;
        box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        top: -4px;
    }
    .progress-section {
        margin: auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        grid-gap: 4px;
    }

    .module-description {
        margin: auto auto auto 0;
        p {
            color: $text-color;
            margin: auto;
            font-size: 16px;
        }

        .module-description__title {
            color: $primary-color;
            font-size: 12px;
        }

        h3 {
            font-weight: 100px;
            margin: 10px auto;
        }

        .test-completed {
            color: $success-color;
        }

        .warning-test {
            color: $warning-color;
        }
    }

    .module-next {
        margin: auto;
    }
}
