@import "src/styles/variables";

.reminder-container {
	display: flex;
	padding: 5%;

	.display-icon {
		margin-right: 7%;

		img {
			cursor: pointer;
		}
	}

	.display-text {
		h1 {
			margin: auto 0;
		}
	}

	.react-datepicker-wrapper {
		color: transparent;
		border: none;
		outline: none;
		width: 0 !important;
		.react-datepicker__input-container {
			color: transparent;
			border: none;
			outline: none;
			.date-picker-input,
			.react-datepicker-ignore-onclickoutside {
				color: transparent;
				border: none;
                outline: none;
                background-color: transparent;
			}
		}
	}

	.react-datepicker__month-container {
		.react-datepicker__header {
			background-color: $primary-color !important;
			.react-datepicker__current-month {
				color: $white-color !important;
			}

			.react-datepicker__day-names {
				.react-datepicker__day-name {
					color: $white-color !important;
					font-weight: bold;
				}
			}
		}

		.react-datepicker__month {
			.react-datepicker__week {
				.react-datepicker__day {
					color: $primary-black !important;
				}

				.react-datepicker__day--selected,
				.react-datepicker__day--keyboard-selected {
					background-color: $primary-color !important;
					color: $white-color !important;
				}
			}
		}
	}
}
