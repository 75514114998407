@import "src/styles/variables";

.topic-container {
	background-color: $white-color;
	padding: 20px 5%;
	margin: auto;
	width: 100%;
	height: 100%;
	box-shadow: 0px 8px 10px #f0f2f5;
	border-radius: 3px;
	cursor: pointer;

	&:hover {
		transition: all 0.2s ease-out;
		box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
		top: -4px;
	}

	.text-container {
		display: flex;
		justify-content: space-between;
		margin-top: 4%;
		p {
			margin: auto;
			font-size: 12px;
			color: $text-color;
			font-weight: bold;
			color: #a0a0a0;
			text-transform: capitalize;
		}

		.success-text {
			color: $success-color;
		}

		.warning-text {
			color: $warning-color;
		}

		h2 {
			margin: auto;
			font-weight: 200;
			font-size: 16px;
		}

		i {
			margin: auto 0 0 0;
			color: $text-color;
		}
	}
}

@media only screen and (max-width: 1040px) {
	.list-topic__container .list-topics {
		grid-template-columns: 1fr 1fr !important;
	}
}
