@import "src/styles/variables";

.topic-progress {
    height: 3px;
    border-radius: 25px;
    background-color: $secondary-light-color;
    width: 100%;
    .bar {
        height: 3px;
    }
}

.success-progress {
    background-color: $success-color;
}

.warning-progress {
    background-color: $warning-color;
}

.danger-progress {
    background-color: $danger-color;
}