@import "src/styles/variables";

.app-toast {
  position: fixed;
  z-index: 10;
  width: 400px;
  top: 1rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  box-shadow: $primary-shadow;
  background-color: #FFFFFF;
  padding: 1.5rem 0;
  border-radius: 12px;
  font-weight: bold;

  &.success {
    color: $success-color;
  }

  &.error {
    color: $danger-color;
  }
}
