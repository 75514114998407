@import "src/styles/variables";

.verify-email-form {
  background-color: #FFFFFF;
  box-shadow: $primary-shadow;
  border-radius: 12px;
  padding: 3rem 2rem;
  font-size: 14px;
  .ui.form input,.ui.form input, .ui.button {
    font-size: 18px;
  }
  .ui.form input,.ui.form input:focus {
    color: $primary-black;
  }

}

.mail-conformation {
  width: 85%;
  margin: 8% auto auto;
  margin-bottom: 2rem;
}

@media (min-width: 767px) {
  .verify-email-form {
    display: block;
    width: 75%;
    margin: 0 auto;
  }
}
