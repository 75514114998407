.brand-card__container {
	box-shadow: 0px 10px 20px rgba(67, 104, 178, 0.0544611);
	border-radius: 12px;
	overflow: hidden;
	color: black;
	background-color: white;
	margin-top: 4%;
	cursor: pointer;
	.img-container {
		overflow: hidden;
		height: 20vh;
		width: 100%;
		img {
			height: inherit;
			width: inherit;
			object-fit: cover;
		}
	}
	.content {
		padding: 5% 7%;
		.title {
		}
		.description {
		}

		.description-card {
			// white-space: nowrap;
			// overflow: hidden;
			// text-overflow: ellipsis;
		}
		.date {
			margin-top: 4%;
			opacity: 0.6;
		}
	}
	.control {
		margin: 0 7% 3%;
		display: flex;
		justify-content: space-between;
		.article-controller {
			outline: none;
            background-color: #e5e5e5;
            border: none;
            padding: 1% 2.5%;
            border-radius: 5px;
            cursor: pointer;
		}
	}
}
