
/* Color palette */
$primary-color: #A9070B;
$secondary-color: #006FBA;
$ternary-color: #363740;
$primary-light-color: #0BB14E20;
$primary-dark-color: #FFB0B2;
$primary-black: #172B4D;
$black: #000000;
$dark-blue: #22509D;
$white-color: #FFFFFF;
$light-white: #FAFAFA;
$medium-white: #B6C1D4;
$secondary-light-color: #EAEDF2;
$dark-white: #6F7981;
$text-color: #424242;
$light-grey: #B9C1C8;
$dark-grey: #ADB6BE;
$medium-grey: #ECEFF2;
$success-color: #5EA66E;
$warning-color: #F5BA26;
$danger-color: #FE0000;
$body-bg: #F7F8FA;
$table-bg: #F7FAFC;
$input-bg: #F7F8F9;

/* Shadows */
$primary-shadow: 0px 10px 20px rgba(67, 104, 178, 0.0544611);
$secondary-shadow: 0px 3px 6px #2C28281C;

/* Spacer variables */
$spacer-x : 1rem;
$spacer-y : 1rem;

/* Border radius */
$default-radius: 10px;
$input-radius: 3px;

/* Boder */
$input-border: 1px solid rgba(34,36,38,.15);

/* Padding */
$input-padding: .67857143em 1em;
