@import "src/styles/variables";

.topic-vide-container {
	width: calc(100vw - 160px);
	float: right;
	.topic-video-player-container {
        padding: 1rem 8rem;
        margin-top: 170px;

        .vimeo-player {
            margin-top: 5%;
            background-color: $white-color;
            text-align: center;
            padding: 3%;
                p {
                    margin: auto;
                    color: $primary-color;
                    font-size: 14px;
                }
                h2 {
                    margin: auto 0 3% 0;
                }
            .video-player {
                iframe {
                    width: 100%;
                    border-radius: 5px;
                }
            }
        }
	}
}

@media only screen and (max-width: 768px) {

    .topic-vide-container {
        width: 100% !important;

        .topic-video-player-container {
            margin-top: 250px !important;
            padding: 1rem;
        }
    }
}