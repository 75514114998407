.list-blog__container {
  padding: 3% 5%;
  color: #000;
  background-color: white;
  margin-top: 4rem;
  .header {
    margin-top: 1.5rem;
    h2 {
      text-transform: uppercase;
    }
    p {
      margin: 2px 0;
    }
  }
  .brand-new {
    margin-top: 3%;
  }
  .list-blog {
    margin-top: 3%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 3%;
  }
  .popular-food {
    margin-top: 4%;
    .title {
    }

    .card-wrapper {
      margin-top: 2%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 3%;
    }
  }
}
.navbar {
  display: flex;
  justify-content: space-between;
}
.navbar-nav {
  list-style: none;
  display: flex;
}

.wraper-header {
  position: fixed;
  z-index: 10;
  background-color: white;
  padding: 0px 18px;
  box-shadow: 0px 0px 2px rgb(0, 0, 0 / 10%);

  .logo-text {
    color: #a9070b;
  }

  .nav-link {
    color: #000 !important;
    font-size: 16px !important;
    font-weight: 520;
  }
  .login-btn {
    border: 1px solid black;
    border-radius: 28px;
    color: #000;
    padding: 8px 20px !important;
    font-weight: bold;
  }
  .sign-up {
    a {
      border: 1px solid #a9070b;
      background-color: #a9070b;
      border-radius: 20px;
      color: white;
      font-weight: bold;
      padding: 8px 20px !important;
    }
  }
}

.main-navbar-content > ul > li.li-has-children > a:after {
  display: none;
}

@media only screen and (max-width: 780px) {
  .list-blog__container {
    .list-blog {
      grid-template-columns: 1fr;
    }
    .popular-food {
      .card-wrapper {
        grid-template-columns: 1fr;
      }
    }
  }
}
.red-text {
  color: #a9070b;
}
