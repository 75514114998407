@import "src/styles/variables";

.drug-chart__container {
	padding: 80px 14%;
	.header {
		text-align: center;
	}

	.content {
		text-align: center;
		margin-top: 4%;
		p {
			line-height: 2rem;
		}
	}

	.download {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 3%;
		.download__btn {
			background-color: white;
			box-shadow: 0px 8px 10px #f0f2f5;
			display: flex;
			justify-content: space-between;
			padding: 2% 5%;
			width: 100%;
			margin: 1%;
			margin-top: 8%;
			cursor: pointer;
			.text {
				h3 {
					margin: auto 0;
					color: $text-color;
				}

				p {
					margin: auto 0 5px;
					font-size: 12px;
					color: $text-color;
				}
			}

			.download-image {
				margin: auto 0;
				img {
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.drug-chart__container {
        padding: 8%;
        margin-top: 25%;
        .download {
            display: block !important;
        }
    }
}
