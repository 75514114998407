@import "src/styles/variables";

.loader-container {
	background: rgba(0, 0, 0, 0.5) no-repeat;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
    z-index: 999;
    margin: auto;
    .ui.activ.loader {
        margin: auto;
    }

    .ui.inverted.loader:after {
        // border-top-color: white;
    }
}
