@import "src/styles/variables";

.mode-content__container {
    height: 100%;
    width: 100%;
	.content {
		border: 1px solid black;
		padding: 5% 8%;
		// min-height: 80px;
		cursor: pointer;
		background-color: rgba($color: #278C3D, $alpha: 0.13);
		p {
			text-align: center;
			margin: auto;
		}
    }
    
    p {
        text-align: center;
        margin: auto;
    }

	.content-active {
		background-color: #1fa124;
		color: white !important;
	}

	.content-quiz {
		padding: 5% 8%;
		border: 1px solid #000;
		border-radius: 30px;
		// min-height: 80px;

		p {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
		}
	}

	.content-left {
		border-radius: 30px 0 0 30px;
        height: 100%;
        width: 100%;
		p {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
		}
	}

	.content-right {
        border-radius: 0 30px 30px 0;
        height: 100%;
        width: 100%;
		p {
			margin: auto;
		}
	}

	.content-disabled {
		pointer-events: none;
		color: #bdbdbd;
	}
}

.list-options {
	color: #494a50;
	font-weight: lighter;
	text-align: left;
	margin: auto;
	margin-left: 20%;

	li {
		margin-bottom: 4%;
	}
}

.list-description-1 {
	min-height: 150px;
}
