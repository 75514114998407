@import "src/styles/variables";

.question-palette__container {
	border-radius: $default-radius;
	overflow: hidden;
	grid-area: "questionPalette";
	.question-palette__container__header {
		text-align: center;
		margin-bottom: 50px;
		display: block;
	}
	.question-palette__inner__container {
		height: 100%;

		.question-palette__header {
			display: flex;
			justify-content: space-between;
			background-color: $ternary-color;
			color: $white-color;
			margin: auto;
			padding: 2%;
			p {
				margin: 0;
			}
			.exam-mode {
				border: 1px solid #9aa4bc;
				color: #9aa4bc;
				padding: 0.5% 3%;
				border-radius: 20px;
				width: 150px;
			}
		}
		.question-palette__body {
            background-color: $white-color;
            .question-no-padding {
                padding: 0px !important;
			}
			.remove-case__description {
				display: none !important;
			}
			.question-palette__case__left {
				padding: 20px 3%;
				border-right: 1px solid #eaedf2;
				p {
					line-height: 25px;
				}
			}
			.question-palette__case__right {
				.question-palette__ques {
					padding: 20px 30px 15px;
					min-height: 15vh;
					p {
						line-height: 2rem;
					}
				}
				.question-palette__options {
				}
			}
		}

		.question-palette__calculator {
			background-color: #e0e5e8;
			color: $ternary-color;
			padding: 1.5% 2%;
			margin: 1vh 0 auto auto;
			text-align: center;
			width: 150px;
			border-radius: 4px;
			cursor: pointer;
			display: block;
		}

		.question-palette__calculator-active {
			background-color: $primary-color !important;
			color: $white-color !important;
		}

		.question-palette__split__screen {
			display: grid;
			grid-template-columns: 1fr 1fr;
		}
		.question-palette__footer {
			padding: 2%;
			display: flex;
			position: relative;
			background-color: $ternary-color;
			border-radius: 0px 0px 10px 10px;
			.end-quiz {
				position: absolute;
				right: 15px;
			}
			.question-palette__controllers {
				margin: auto;
				display: flex;

				button {
					margin: 0 10px;
				}

				.question-palette-sm {
					display: none;
				}
				.question-palette-flag {
					background: $white-color !important;
					.flag.icon {
						background: $white-color !important;
					}
				}
				.question-palette-xl {
					background-color: $white-color !important;
					display: block;
					.arrow.right.icon {
						background-color: $white-color !important;
					}
					.arrow.left.icon {
						background-color: $white-color !important;
					}
				}
			}
		}
	}
	.display-exam-answer {
		background-color: white;
		padding: 30px 20px;
	}
	.answer-explanation__text {
		margin-bottom: 12px;
		text-decoration: underline;
		font-weight: bold;
	}
}

.public-DraftEditor-content {
	div {
		span {
			margin: 20px 0px;
		}
		img {
			margin: 30px 0px;
		}
	}
}

.highlighter-background {
	background-color: White;
	color: black;
	padding: 0.5% 1%;
	border-radius: 8px;
	box-shadow: 0px 12px 25px #00000014;

	.content-highlighter {
		cursor: pointer;
	}
}

.public-DraftEditor-content {
	color: white;
	text-shadow: 0px 0px 0px #000;
	-webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 768px) {
	.application-body {
		padding: 1rem;
	}

	.question-palette__split__screen {
		grid-template-columns: 1fr !important;
		.question-palette__case__left {
			margin-bottom: 10%;

			img {
				width: 100% !important;
			}
		}
	}
	.question-palette__container {
		grid-area: quespalette;
		.question-palette__container__header {
			display: none;
		}
		
		.question-palette__inner__container {
			.question-palette__header {
				.xs-disable {
					display: none;
				}
			}

			.question-palette__footer {
				display: block;
				padding: 3%;
				.end-quiz {
					position: static;
				}
				.question-palette__controllers {
					margin-bottom: 7%;
					.button {
						margin: 0px 6px !important;
                        padding: 10px !important;
					}

					.ui.labeled.icon.button {
                        padding: 10px 0px !important;
					}
					.ui.labeled.icon.button,
					.ui.labeled.icon.buttons .button {
                        padding: 10px 0px !important;
                    }
					.question-palette-sm {
						background-color: $white-color !important;
						display: block;
						.arrow.right.icon {
							background-color: $white-color !important;
						}
						.arrow.left.icon {
							background-color: $white-color !important;
						}
					}
					.question-palette-xl {
						display: none;
					}
				}
			}
		}
	}
}

.question-palette__case__left  {
	.public-DraftStyleDefault-ltr {
		img {
			width: 100% !important;
		}
	}
}