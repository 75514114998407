@import "src/styles/variables";

.correct-icon {
    color: #44c47a !important;
    font-size: 20px;
}

.worng-icon {
    color: #f0394f !important;
    font-size: 20px;
}