@import "src/styles/variables";

.result-progress__container {
	background-color: $white-color;
	padding: 4% 5%;
	margin: auto;
	display: flex;
	justify-content: space-between;
	box-shadow: 0px 10px 20px rgba(67, 104, 178, 0.0544611);
	border-radius: 4px;
	margin-top: 5%;
	.result-progress__left {
		display: flex;
		width: 40%;
		justify-content: space-around;
		.result-progress__progress__bar {
			width: 25%;
		}
	}
	.result-progress__right {
		margin: auto 0;
	}
}

@media only screen and (max-width: 768px) {
	.result-progress__container {
		.result-progress__left {
            width: 70%;
            .result-progress__progress__bar {
                width: 20%;
            }
		}
	}
}
