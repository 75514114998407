@import "src/styles/variables";

.ui.menu.app-header {
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 5rem;
	padding: 1.5rem;
	box-shadow: $primary-shadow;
	background-color: #ffffff;
	border-radius: 4px;

	.search-bar-global-xl {
		display: inline-flex;
	}

	.search-bar-global-sl {
		display: none;
	}

	.search-bar-global {
		background-color: #f7f8fa;
		height: 35px;
		margin-left: 2%;
		border-radius: 30px;
		input {
			border: none;
			outline: none;
			background-color: inherit;
		}
		div {
			font-size: 100% !important;
			width: calc(100vw - 515px);

			input {
				width: 100%;
			}

			div {
				padding: 10px 5px;
				overflow: none  !important;
			}
		}
		.global-search-icon {
			z-index: 1;
			color: #bababa;
			height: 20px;
			width: 20px;
		}
	}

	.item {
		&:before {
			content: none;
		}
		img {
			width: 100px;
		}
		i,
		svg {
			cursor: pointer;
			font-size: 24px;
		}
	}

	.app-header__item {
		color: $dark-white;
	}

	.menu-item-disabled-xl {
		color: #bdbdbd;
	}

	.menu-item-disabled-xl:hover {
		color: #bdbdbd !important;
		background-color: transparent;
	}

	.item.app-header__menu-item {
		&:hover,
		&.active {
			background-color: $primary-color;
			color: $white-color;
			font-weight: normal;
			border-radius: 4px;
			i:before {
				color: $white-color;
			}
		}
		i {
			display: block;
			font-size: 1.5rem;
			margin-bottom: 10px;
		}
		div {
			display: flex;
			margin: auto;
			align-items: baseline;
		}
	}
	.app-header__menu-item-responsive {
		margin: auto;
		padding: 0px;
		display: none;
	}

	.sidebar-sm.menu {
		display: none !important;
	}
}

.buy-course__modal {
	padding: 5%;
	width: 60vw !important;
	.description {
		color: #494a50;
	}

	.pruchase-all-course {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
}

.static-app-header {
	margin: 0 auto !important;
	.item::before {
		width: 0px !important;
	}
	.item {
		font-weight: bolder !important;
	}

	.item.title {
		font-size: 18px;
		padding-left: 0px !important;
	}

	.header-logo {
		width: 7em !important;
	}

	.active.item {
		background: transparent !important;
		color: $primary-color !important;
	}

	.item:hover {
		background: transparent !important;
		color: $primary-color !important;
	}

	.static-right-btn {
		padding-left: 5px !important;
		padding-right: 5px !important;
	}

	.static-right-sl {
		display: none !important;
	}
}

@media only screen and (max-width: 768px) {
	.large {
		.title {
			display: none !important;
		}

		.right {
			.static-right-btn {
				display: none !important;
			}
			.static-right-sl {
				display: block !important;
				margin: auto 0;
				font-size: 20px;
				padding-left: 5px !important;
				padding-right: 5px !important;
			}
		}

		.large-link {
			display: none !important;
		}
	}
	.menu-item-sx {
		text-align: center;
		padding: 10px;
		margin: 5% 10%;
		color: #bdbdbd !important;
		&:hover,
		:active {
			background-color: #494a50 !important;
		}
	}

	.ui.menu.app-header {
		padding: 1.5rem 0.1rem !important;
	}

	.search-bar-global {
		div {
			width: 40vw !important
		}
	}

	.search-bar-global-xl {
		display: none !important;
	}

	.search-bar-global-sl {
		display: inline-flex !important;
	}

	.search-icon__alone {
		height: 2rem;
	}

	.menu-item-sx::before {
		background: #494a50 !important ;
	}
	.ui.menu.app-header {
		.menu-item-xl {
			display: none;
		}
		.menu-item-disabled-xl {
			display: none;
		}
		.app-header__menu-item-responsive {
			display: block;
		}
	}

	.ui.secondary.menu.app-sidebar {
		height: 100% !important;
	}

	.sidebar-sm {
		background-color: $ternary-color !important;
		display: flex !important;
		flex-direction: column !important;
		justify-content: space-between !important;

		.right.header.item {
			height: 8%;
		}

		.item.app-sidebar__menu-item::before {
			height: 0px !important;
		}

		.right.header.item::before {
			height: 0px !important;
		}

		.app-sidebar__select__sub {
			text-align: center;
			padding: 10px;
			margin: 5% 10%;
			border-radius: 4px !important;
		}

		.app-sidebar__select__sub::before {
			height: 0px !important;
		}

		.sidebar-close-icon {
			font-size: 24px;
			float: right;
			font-weight: lighter;
			cursor: pointer;
		}

		.app-sidebar__select__sub {
			&:hover,
			&.active {
				background-color: $primary-color !important;
				color: $white-color !important;
				font-weight: normal !important;
				border-radius: 4px !important;
				i:before {
					color: $white-color;
				}
			}
			i {
				display: block !important;
				font-size: 1.5rem !important;
				margin-bottom: 10px !important;
			}
			div {
				display: flex !important;
				margin: auto !important;
				align-items: baseline !important;
			}
		}

		.item.app-sidebar__menu-item {
			&:hover,
			&.active {
				background-color: $primary-color !important;
				color: $white-color !important;
				font-weight: normal !important;
				border-radius: 4px !important;
				i:before {
					color: $white-color;
				}
			}

			div {
				display: flex !important;
				margin: auto !important;
				align-items: baseline !important;
				p {
					margin: auto 15px;
					font-size: 18px;
				}
			}
		}
		.app-sidebar__menu-item-bottom {
			&::before {
				background: none !important;
			}
			display: flex !important;
			margin: auto !important;
			align-items: baseline !important;

			p {
				margin: auto 15px;
				font-size: 18px;
			}

			img {
				font-size: 10px !important;
			}
		}

		.app-sidebar-logout {
			&::before {
				background: none !important;
			}
			svg {
				font-size: 24px !important;
			}
			display: flex !important;

			p {
				margin: auto 15px;
				font-size: 18px;
			}
		}

		.item:before {
			display: none !important;
		}
	}
}

@media only screen and (max-width: 462px) {
	.search-bar-global {
		div {
			width: 120px !important;
		}
	}
}