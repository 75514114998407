@import "src/styles/variables";

.buy-course__container {
    border-radius: 20px !important;
    box-shadow: 0px 10px 20px rgba(67, 104, 178, 0.0544611);
    width: 20vw;
    margin: 0 auto;
    padding-bottom: 5%;
    background-color: $white-color;
    .coming-soon {
        margin-top: 10%;
        h1 {
            text-align: center;
        }
    }
    .course-heading {
        background: linear-gradient(90deg, #ff0000 0%, rgba(255, 0, 0, 0) 100%),
            #a9070b;
        border-radius: 25px 25px 0px 0px;
        text-align: center;
        color: $white-color;
        padding: 2% 0;
    }
    .monthly-access {
        text-align: center;
        font-weight: bold;
        padding: 4% 0;
    }

    .feature-contents {
        .feature-content {
            margin: 3% 5%;
            padding-bottom: 5%;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #e9e9e9;

            .tick-icon {
                height: 20px;
                margin: auto 0;
            }

            p {
                margin: auto 0;
            }
        }
    }
    .buttom-item {
        .price {
            text-align: center;
            padding: 5% 0;
            p {
                font-size: 3vh;
                font-weight: bold;
                color: black;
            }
        }
        .buy-now {
            background: linear-gradient(
                    90deg,
                    #ff0000 0%,
                    rgba(169, 7, 11, 0) 100%
                ),
                #a9070b;
            border-radius: 116px;
            text-align: center;
            font-weight: bold;
            color: $white-color;
            padding: 2% 12%;
            width: 70%;
            margin: auto;
            cursor: pointer;
        }
    }
}
