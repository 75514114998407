@import "src/styles/variables";

.exam-description-content {
  margin-top: 3rem;
  .exam-description__container {
    @media screen and (max-width: 1600px) {
      margin-top: 1rem;
    }
    margin-top: 0;

    .back-btn__container {
      .back-btn {
        float: left;
      }
    }
    .exam-description__wrapper {
      .create-quiz {
        .start-quiz-btn {
          background-color: $primary-color;
          color: $white-color;
          display: flex;
          justify-content: space-between;
          width: 30%;
          padding: 2% 3%;
          border-radius: 4px;
          margin: auto;
          margin-left: 46%;
          cursor: pointer;
          p {
            margin: auto;
          }

          i {
            margin: auto 0;
          }
        }

        h2 {
          font-weight: bolder;
          margin-bottom: 3%;
          margin-left: 15vw;
        }

        .choose-mode {
          display: grid;
          grid-template-columns: 1fr 4fr;
          margin-bottom: 4%;

          .text {
            margin: auto;
            p {
              font-size: 20px;
            }
          }
          .mode-content-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
          }
        }
      }

      .header {
        margin-top: 2rem;
        margin-left: 15vw;
        p {
          margin: auto;
          color: $primary-color;
          font-size: 14px;
          font-weight: lighter;
        }

        h2 {
          margin: 0.5rem auto;
        }
      }

      .start-desc {
        p {
          text-align: center;
          font-weight: lighter;
          margin-left: 15vw;
        }

        h2 {
          margin: 0.5rem auto;
          text-align: center;
        }
      }

      .start-exam {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        margin-top: 10%;
        .exam-button {
          display: flex;
          justify-content: space-between;
          padding: 5% 3%;
          background-color: $white-color;
          box-shadow: 0px 8px 10px #f0f2f5;
          cursor: pointer;
          p {
            margin: auto 0;
          }
        }

        .message {
          margin-top: 5%;
          p {
            font-weight: lighter;
            line-height: 2rem;
          }
        }
        .study-mode {
        }

        .exam-mode {
        }
      }
    }
    .exam-description__layout {
      display: grid;
      justify-items: center;
      align-items: center;

      .header,
      .start-desc p,
      .create-quiz h2 {
        margin-left: 0;
        margin-top: 0;
        font-weight: 600;
      }

      .start-desc p {
        font-size: 1.5rem;
        margin-bottom: 1.25rem;
      }

      .header p {
        font-weight: 600;
      }

      .create-quiz {
        display: grid;
        gap: 1rem;

        @media screen and (min-width: 800px) and (max-width: 1700px) {
          width: 75%;
        }
        h2 {
          margin-bottom: 0;
          font-size: 1.4rem;
          font-weight: 600;
        }

        .choose-mode {
          column-gap: 2.5rem;
          grid-template-columns: auto 4fr;
          margin-bottom: 0;

          .text {
            text-transform: capitalize;
            width: 60px;
            p {
              font-size: 1.1rem;
              font-weight: 600;
            }
          }
          .mode-content-wrapper {
            .mode-content__container {
              .content {
                align-items: center;
                display: flex;
                font-weight: 500;
                justify-content: center;
                padding-block: 0.65rem;

                &.content-left {
                  border-right: 1px solid transparent;
                }
              }
            }
          }
        }

        .list-description {
          min-height: auto;

          .list-options {
            color: #000;
            margin-left: 10%;
            list-style: none;

            li {
              display: flex;
              gap: 8px;
              margin-bottom: 1.5%;

              &::before {
                content: "o";
                font-weight: 900;
                font-size: 12px;
              }
            }
          }
        }

        .start-quiz-btn {
          margin: 0;
          margin: auto;
          max-width: 160px;
          font-weight: 600;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .exam-description__container {
    .start-exam {
      grid-template-columns: 1fr !important;
    }
  }
}

// @media only screen and (max-width: 1680px) {
// 	.start-desc {
// 		p {
// 			margin-left: 17% !important;
// 		}
// 	}

// 	.create-quiz {
// 		h2 {
// 			margin-left: 20% !important;
// 		}
// 	}
// }
