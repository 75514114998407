@import "src/styles/variables";

.notification__container {
    display: grid;
    width: 100%;
    grid-template-columns: 4fr 1fr;
    grid-gap: 5px;
    margin: 15px auto;
    background-color: $primary-dark-color;
    color: $primary-color;
    padding: 10px;
    p {
        margin: 0;
        font-size: 14px;
        color: $primary-color;
    }
    .notification__right {
        color: inherit;
        text-align: center;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 5px;
        margin: auto;
        .notification__right__link {
            color: inherit;
            text-decoration: underline;
        }
        div {
            background-color: $primary-color;
            color: white;
            padding: 2px 4px;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            margin: auto;
            text-align: center;
            cursor: pointer;
        }
    }
}
