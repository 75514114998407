.pagenation__container {
    margin-top: 3%;
    text-align: center;

    .ui.pagination.menu {
        a[type="firstItem"] {
            display: none !important;
        }

        a[type="lastItem"] {
            display: none !important;
        }
    }
}