@import "src/styles/variables";

.ui-custom-btn__container {
    background-color: #E0E5E8;
    border-radius: 10px;
    cursor: pointer;

    button {
        background-color: transparent;
        border: none;
        color: $ternary-color !important;
        outline: none;
    }

    p {
        color: $ternary-color !important;
        font-weight: lighter !important;
        margin: auto 0;
        padding: 8px 25px;
    }
}