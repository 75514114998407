@import "src/styles/variables";

.card-field {
	background-color: $input-bg;
	border: $input-border;
	padding: $input-padding;
	border-radius: $input-radius;
}

.purchase-course__container {
  margin-top: 5%;
	h1, p, img {
		text-align: center;
  }
  
  .logo {
    display: flex;
  }


  .billing-info {
    margin-top: 3%;
  }
}
