.brand-new__container {
  height: 50vh;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  color: white;
  border-radius: 50px;
  padding: 2%;
  position: relative;
  .content {
    position: absolute;
    bottom: 5%;
    width: 55%;
  }
}
