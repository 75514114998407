@import "src/styles/variables";

.exam-form-container {
	margin-top: 1.5%;
	.exam-form__header {
		display: none;
		h1 {
			text-align: center;
			margin-bottom: 2%;
			height: 50%;
		}

		.exam-form-drop-down-icon {
			display: none;
		}
	}
	.exam-form__wrapper {
		display: grid;
		grid-template-columns: 6fr 1fr;
		grid-gap: 5%;
	}
}

.calculator-container {
	.react-calculator {
		box-shadow: 10px 10px 30px rgba(19, 42, 87, 0.24);
		border-radius: 4px;
		overflow: hidden;
		.result-panel {
			background-color: #fafafa;
		}
		.react-calc.button-panel.row {
			.s3.column {
				.s1.row {
					.button.s1 {
						background-color: #e0e5e8 !important;
						border: 1px solid #fafafa;
					}
					.button.s2 {
						background-color: #e0e5e8 !important;
					}
				}
			}
			.s1.column {
				.last {
					background-color: #5ea66e !important;
				}
			}
		}
	}
}


@media only screen and (max-width: 768px) {
	.exam-form-container {
		.exam-form__header {
			display: block;
			margin-bottom: 5%;
			display: flex;
			justify-content: space-between;
			h1 {
				text-align: left;
			}
			.exam-form-drop-down-icon {
				display: block;
				float: right;
				margin: 0;
			}
		}
		.exam-form__wrapper {
			grid-template-columns: 1fr;
			grid-template-areas:
				"quesno"
				"quespalette";
		}
	}
}
