@import "src/styles/variables";

.card-container {
	width: 100%;
	height: 100%;
	box-shadow: 0px 10px 20px rgba(67, 104, 178, 0.0544611);
	border-radius: 12px;
	overflow: hidden;
	background-color: white;

	.white-color-title {
		p {
			color: white !important;
		}
		min-height: 40px !important;
	}
	.card-container__header {
		text-align: center;
		background-color: $secondary-light-color;
		width: 100%;
		min-height: 50px;
		p {
			text-align: center !important;
			font-size: 15px;
			padding: 10px 30px;
			font-weight: bolder;
			text-align: left;
			color: black;
			margin: auto 0;
		}
	}

	.card-secondary {
		background-color: $primary-color;
		color: $white-color;
		margin: auto;

		p {
			text-align: left !important;
		}
	}

	.card-container__body {
		width: inherit;
		height: inherit;
		color: black;
	}
	.btn-preset {
		margin: auto 20px auto auto;
		p {
			background-color: $primary-color;
			color: white;
			margin: auto;
			padding: 7px 10px;
			border-radius: 6px;
			cursor: pointer;
		}
	}

	.space-between {
		display: flex;
		justify-content: space-between;
	}
}

@media only screen and (max-width: 830px) {
	.card-container {
		.card-container__header {
			p {
				font-size: 14px;
			}
		}
	}
}
