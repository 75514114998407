.module__container {
    margin-top: 5%;
    padding: 1rem 3rem;
    .title {

    }
    .module-content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}