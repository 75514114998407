@import "src/styles/variables";

.list-item-container {
    padding: 15px 30px;
    border: 1px solid #eaedf2;

    .score-description {
        background-color: #FFFAEE;
        margin-top: 2%;
        p {
            color: 494A50;
            margin: auto;
            text-align: center;
            padding: 12px 0px;
            font-size: 14px;

            span {
                font-weight: bold;
            }
        }
    }

    .list-item-inner__container {
        display: flex;
        justify-content: space-between;
    }
    .left {
        p {
            font-size: 12px;
        }
    }

    .right {
        margin: auto 0 0;
        width: 40px;
        display: flex;
        justify-content: space-between;
        i {
            margin: auto 0;
        }
        .percentage {
        }
    }

    .minimal-right {
        margin: auto 0 !important;
        width: 3% !important;
    }

    p {
        // margin: auto 0;
        color: $text-color;
        font-weight: lighter;
        font-size: 12px;
    }

    h2 {
        margin: auto 0;
        color: $ternary-color;
        font-size: 14px;
        text-transform: uppercase;
    }

    .topic-progress-container {
        margin-top: 5%;
        p {
            font-size: 12px;
        }
       
        .max-width {
            .bar {
                min-width: 4.5% !important;
            }
        }

        .list-progress_bar-success {
            .bar {
                background-color: #44c37a;
                .progress {
                    color: $white-color;
                }
            }
        }

        .list-progress_bar-warning {
            .bar {
                background-color: #f5ba26;
                .progress {
                    color: $white-color;
                }
            }
        }

        .list-progress_bar-danger {
            .bar {
                background-color: $primary-color;
                .progress {
                    color: $white-color;

                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .list-item-inner__container {
        width: 100%;
    }
    .left {
        margin: auto 0 !important;
    }
    .list-sl {
        padding: 5% !important;
    }
}

@media only screen and (max-width: 1108px) {
    .list-item-container {
        .list-item-inner__container {
            width: 94%;
        }
        .left {
            p {
                font-size: 12px;
            }
        }
        h2 {
            font-size: 16px;
        }
    }
}

@media only screen and (max-width: 875px) {
    .list-item-container {
        .list-item-inner__container {
            width: 90%;
        }
    }
}
