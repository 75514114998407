@import "src/styles/variables";

.box-container {
    height: 8px;
    width: 8px;
}

.success-box {
    background-color: $success-color;

}

.warning-box {
    background-color: $warning-color;

}

.default-box {
    background-color: #EAEDF2;

}
