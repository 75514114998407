@import "src/styles/variables";
.auth-wrapper {
  height: 100vh;
  overflow-y: scroll;
  .auth-wrapper__logo-section {
    margin: 5rem 0 2rem 0;
  }

  .auth-wrapper__auth-section {
    position: static;
  }

  .auth-wrapper__logo-section-content {
    text-align: center;
    img {
      width: 200px;
    }
  }
}


@media (min-width: 767px) {
  .auth-wrapper {
    .auth-wrapper__logo-section {
      width: 60%;
      margin: 7rem auto 2rem auto;
    }

    .auth-wrapper__auth-section {
      width: 55%;
      margin: 0 auto;
    }
  }
}
