@import "src/styles/variables";

.modules-container {
    margin-top: 50px;
    .modules-body {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-rows: auto;
        grid-gap: 1rem;
        margin-top: 1rem;
    }
    .modules-text {
        text-align: center;
        margin-top: 3rem !important;
    }

    .modules-exam-mode {
        background: $white-color;
        display: flex;
        justify-content: space-between;
        padding: 2%;
        box-shadow: 0px 8px 10px #F0F2F5;
        width: 35%;
        margin: auto;
        cursor: pointer;
        p {
            margin: auto 0px;
        }

        &:hover {
            transition: all 0.2s ease-out;
            box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
            top: -4px;
        }
    }
}

@media only screen and (max-width: 1000px) {
    .modules-body {
        display: grid !important;
        grid-template-columns: 1fr 1fr !important;

    }
}

@media only screen and (max-width: 768px) {
    .modules-body {
        display: grid !important;
        grid-template-columns: 1fr !important;

    }

    .modules-exam-mode {
        padding: 5% !important;
        width: 90% !important;
    }
}
