@import "src/styles/variables";

.prep-score__container {
    background-color: $white-color;
    padding: 2% 3%;

    svg {
        padding: 4% 1%;
        width: 14rem;
    }

    text-align: center;

    p {
        margin: auto 0;
    }
}

@media only screen and (max-width: 1108px) {
    .prep-score__container {
        p {
            font-size: 14px;
        }
    }
}
