@import "src/styles/variables";

.number-pannel__container {
	max-height: 100vh !important;
	background: #fafafa;
	box-shadow: 0px 10px 20px rgba(67, 104, 178, 0.0544611);
	border-radius: 12px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 2%;
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	padding: 10% 3%;
	grid-area: "questionNumberPannel";
	grid-auto-rows: max-content;
	margin-top: 75px;
}

@media only screen and (max-width: 768px) {
	.number-pannel__container {
		margin-top: 10px;
		grid-area: quesno;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		grid-gap: 5%;
		height: 100% !important;
	}
}
