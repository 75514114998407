@import "src/styles/variables";

.basic-details-form {
  background-color: #FFFFFF;
  box-shadow: $primary-shadow;
  border-radius: 12px;
  padding: 3rem 2rem;
  margin-bottom: 4rem;
  font-size: 14px;

  .card-field {
    background-color: $input-bg;
    border: $input-border;
    padding: $input-padding;
    border-radius: $input-radius;
  }

  .card-field:focus {
    background-color: red;
  }

  .ui.form label {
    color: $dark-grey;
  }

  .ui.checkbox label {
    color: $black !important;

    .policy-verification-link {
      color: $primary-color;
    }
  }

  .ui.checkbox label::after {
    background-color: $primary-color;
    color: white !important;
    border-radius: 5px;
  }

  .ui.form .form-checkbox {
    margin-top: 3rem !important;
  }

  .ui.form input,.ui.form input, .ui.button, .ui.dropdown {
    font-size: 18px;
  }
  .ui.button {
    display: block;
    width: 70%;
    margin: 0 auto;
  }
  .ui.form input,.ui.form input:focus {
    color: $primary-black;
  }

  .ui.tag.label.label {
    padding-top: 16px;
  }

  .ui.tag.label.label:hover {
    background-color: $light-grey;
  }

  .ui.tag.label.label:active {
    background-color: $light-grey;
    transform: translateY(1px);
  }

  .login-link {
    text-align: center;
    color: black;
      margin-bottom: 2rem;
    p {
      font-size: 14px !important;
    }
  }

  .coupon-code.invalid {
      color: $danger-color;
  }
}
