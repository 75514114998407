@import 'variables';

/* Semantic component style overrides */

/* Buttons */

.ui.button, .ui.buttons .button {
  background-color: $light-grey;
  font-weight: 500;
  &:focus,&:hover {
    background-color: $light-grey;
  }
  &.file-upload-button {
    width: 100%;
  }
}

.ui.primary.button, .ui.primary.buttons .button {
  background-color: $primary-color;
  &:focus,&:hover {
    background-color: $primary-color;
  }
}

.ui.secondary.button, .ui.secondary.buttons .button {
  background-color: $secondary-color;
  &:focus,&:hover {
    background-color: $secondary-color;
  }
}

.ui.button.link-button {
  padding: 0;
  background-color: transparent;
  color: $primary-color;
  &:focus,&:hover {
    background-color: transparent;
    color: $primary-color;
  }
}

.ui.button.button-link {
  background: transparent;
  padding-left: 0;
  padding-right: 0;
  color: $primary-color;
  font-weight: normal;
  &:hover {
    text-decoration: underline;
  }
}


.ui.form input:not([type]), .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=email], .ui.form input[type=file], .ui.form input[type=number], .ui.form input[type=password], .ui.form input[type=search], .ui.form input[type=tel], .ui.form input[type=text], .ui.form input[type=time], .ui.form input[type=url] {
  background-color: $input-bg;
}

.ui.form .ui.selection.dropdown {
  background-color: $input-bg;
}

/* Menu */
.ui.menu {
  font-family: 'Roboto', sans-serif;
}
