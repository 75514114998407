@import "src/styles/variables";


.disable-app-sidebar {
	pointer-events: none;
}

.ui.menu:after {
	content: none !important;
}


.ui.secondary.menu.app-sidebar {
	position: fixed;
	top: 5rem;
	width: 179px;
	height: calc(100vh - 5rem);
	background-color: $ternary-color;
	box-shadow: $primary-shadow;
	margin: 0;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	overflow-y: scroll;

	.app-sidebar__bottom {
		width: 100%;
		// top: 78vh;
	}
	.item {
		margin: 0px;
	}

	.item.app-sidebar__menu-item {
		height: 50px;
		color: $white-color;
		font-size: 0.9rem;
		line-height: 1rem;
		white-space: nowrap;

		.nav-item-container {
			height: 100%;
			margin: auto;
			img {
				padding-right: 5%;
				margin: auto 0;
				height: 16px;
			}

			p {
				margin: auto 0;
				color: $white-color;
			}
		}

		&:hover,
		&.active {
			background-color: $primary-color;
			color: $white-color;
			font-weight: normal;
			i:before {
				color: $white-color;
			}
		}
		i {
			display: block;
			font-size: 1.5rem;
			margin-bottom: 10px;
		}
		div {
			display: flex;
			margin: auto;
			align-items: baseline;
		}
	}

	&::-webkit-scrollbar {
		display: none;
	}

	&::-moz-scrollbar {
		display: none;
		
	}

	&::-ms-scrollbar {
		display: none;
		
	}
}

.app-sidebar__logo-wrapper {
	margin-bottom: 2rem;
	img {
		width: 100%;
	}
}

.app-sidebar-disabled {
	pointer-events: none;
}

@media only screen and (max-width: 768px) {
	.application-body {
		width: 100%;
	}
	.app-sidebar {
		visibility: hidden;
	}
}

@media only screen and (max-device-width: 1024px) {
	.ui.secondary.menu.app-sidebar {
		top: 5rem !important;
	}
}
