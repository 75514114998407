@import "src/styles/variables";

.exam-result-container {
    margin-top: 2rem;
    .exam-result__header {
        text-align: center;
        h1 {
            margin-bottom: 5px;
        }
        p {
            font-size: 14px;
        }
    }
  .exam-result__body {
      margin-top: 3%;
  }
}

@media only screen and (max-width: 768px) {
    .exam-result__header {
        text-align: left !important;
    }
}