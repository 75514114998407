@import "src/styles/variables";

.dashboard-inner-container {
    padding: 2% 1%;
    .dashboard__container {
        display: flex;
        color: #494a50;

        .card-container {
            .card-container__header {
                color: white !important;
            }
        }

        .left {
            height: 100%;
            width: 100%;
            margin-right: 1%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .right {
            // height: 100%;
            width: 100%;
            margin-left: 1%;
        }
        .ui.icon.button.pe-btn.pe-primary-btn.last-seen-btn {
            background-color: #005fff !important;
            color: white !important;
            font-weight: bold;
            min-width: 40%;
            margin-bottom: 3%;
        }
        .last-seen {
            text-align: center;
            .last-seen__inner__container {
                padding: 3%;

                .left-of-course-name {
                    font-size: 24px;
                    font-weight: bold;
                }

                p {
                    margin: auto 0 2%;
                }

                div {
                    // display: flex;
                    // justify-content: space-between;
                }
            }
        }

        .prep-score {
            .performance-score {
                display: block;
                padding: 3%;
                p {
                    text-align: center;
                    margin-bottom: 10%;
                }
                svg {
                    width: 13rem;
                    display: block;
                    margin: auto;
                }

                .last-seen-btn {
                    margin-top: 7%;
                    display: grid;
                    grid-template-columns: 5fr 1fr;
                }
            }
        }

        .inner-navigation {
            margin-top: 20px;
            .nav-item {
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding: 3%;
                border-bottom: 1px solid #e9e9e9;
                color: $primary-black;
                i {
                    cursor: pointer;
                    margin: auto;
                    display: flex;
                    justify-content: flex-end;
                }

                p {
                    margin: auto 0;
                }
            }

            .nav-one {
                display: flex;
            }

            .nav-two {
                display: flex;
            }
        }
    }

    .reminders {
        display: flex;
        margin-top: 3%;
        .calender {
            width: 100%;
            margin-right: 1%;
        }
        .email {
            width: 100%;
            margin-left: 1%;
        }
    }
}

@media only screen and (max-width: 768px) {
    .dashboard__container {
        display: block !important;
        margin-top: 5% !important;
        .inner-navigation {
            margin-top: 5% !important;
            .nav-one {
                display: block !important;
            }

            .nav-two {
                display: block !important;
            }
            .nav-item {
                padding: 3% 5% !important;
            }
        }

        .prep-score {
            margin-top: 5% !important;
        }
    }
    .reminders {
        display: block !important;
        .calender {
            margin-top: 5% !important;
        }

        .email {
            margin-top: 5% !important;
        }
    }
}
