@import "src/styles/variables";

.performace-measure__container {
	margin-top: 50px;
	color: black;
	.header {
		text-align: center;
		p {
			color: $primary-color;
			font-size: 80%;
			font-weight: bold;
		}

		h1 {
			margin-top: 1rem;
		}
	}

	.body {
		display: flex;
		width: 100%;
		margin-top: 3%;
		color: black;
		.body-left {
			width: inherit;
			padding: 1% 1% 1% 0px;
		}

		.body-right {
			width: inherit;
			padding: 1% 0px 1% 1%;
		}

		.module-outlet__container {
			.card-container {
				.card-container__header {
					p {
						color: black;
					}
				}
			}
		}

		.strength-outlet__container {
			margin-top: 5%;
		}

		.weakness-outlet__container {
			margin-top: 5%;
		}

		.prepscore-outlet__container {
			color: black;
		}
	}

	.score-based-last-attempt {
		margin: 3% 0px;
	}
	.chart-view {
		margin-top: 5%;
		.chartjs-render-monitor {
			height: 100% !important;
			width: 100% !important;
			margin: auto;
			padding: 3%;
		}
	}
}

@media only screen and (max-width: 768px) {
	.performace-measure__container {
		.header {
			text-align: left !important;
		}

		.body {
			display: block !important;
			.list-container {

			}
			.body-left {
				width: inherit;
				padding: 0 !important;
			}

			.body-right {
				width: inherit;
				padding: 0 !important;
				color: black;
			}

			.module-outlet__container {
				margin-top: 5% !important;
			}

			.strength-outlet__container {
				margin-top: 5%;
			}

			.weakness-outlet__container {
				margin-top: 5%;
			}

			.prepscore-outlet__container {
				margin-top: 5%;
			}
		}
		.score-based-last-attempt {
			margin-top: 5% !important;
		}

	}
}
