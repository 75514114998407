@import "src/styles/variables";

.answer-container {
	margin-top: 3%;
}

.result-answer__container {
	border-radius: 12px;
	box-shadow: 0px 10px 20px rgba(67, 104, 178, 0.0544611);
	background-color: $white-color;
	margin-top: 5%;
	.result-answer__inner__container {
		.result-answer__header {
			background-color: $ternary-color;
			p {
				color: $white-color;
				padding: 2.5%;
			}
		}

		.result-answer__body {
			p {
				padding: 1.5% 3%;
				// margin: auto;
			}
			min-height: 100px;
		}

		.result-answer__split__case {
			display: grid;
			grid-template-columns: 1fr 1fr;
		}

		.question-palette__options {
			.question-palette-option__container {
				padding: 0px 1%;
			}
		}

		.result-answer__footer {
			background-color: $ternary-color;
			padding: 1.5% 3%;

			.pe-primary-btn {
				background-color: white !important;

				i {
					background-color: white !important;
				}
			}
			.question-palette-sm {
				display: none;
			}

			.question-palette-xl {
				display: inline;
			}
		}

		.result-answer__answer {
			padding: 3%;
		}
	}
	.result-answer__question {
		min-height: 100px;
	}

	.result-answer__case__right {
		img {
			height: 100% !important;
			width: 100% !important;
		}
	}
}

@media only screen and (max-width: 768px) {
	.result-answer__header {
		p {
			padding: 5%;
		}
	}

	.result-answer__split__case {
		grid-template-columns: 1fr !important;
	}

	.result-answer__footer {
		display: flex;
		.question-palette-xl {
			display: none !important;
		}
		.question-palette-sm {
			display: block !important;
		}
	}
}
