@import "variables";
@import "helpers";
@import "semantic_overrides";

/* General component styles */
body {
    background-color: $body-bg;
    color: $primary-black;
}

.application-body {
    width: calc(100% - 179px);
    padding: 10px 25px;
    margin-top: 4rem;
    float: right;
    height: 100%;
    color: black;
}

body,
h1,
h2,
h3,
h4,
h5,
button,
input {
    font-family: "Mulish", "Lato", sans-serif !important;
}

p {
    font-size: 16px;
    // color: black;
}

a,
a:hover,
a:visited {
    color: $primary-color;
}

/* Badge styles */

.badge {
    padding: 5px 20px;
    font-size: 0.8rem;
    font-weight: bold;
    &.rounded {
        border-radius: 50em;
    }
    &.primary {
        background-color: $primary-color;
        color: #ffffff;
    }
}

/* Form styles */

.form-error-message {
    color: $danger-color;
    margin-top: 0.5rem;
}



/* START OF HEADER */
/* ====================== */
.wraper-header { top: 0; -webkit-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }
.wraper-header > .header-main { padding-top: 15px; padding-bottom: 15px; -webkit-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }

.wraper-header.shrink { background-image: none; background-color: #fff; box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1); }
.wraper-header.shrink > .header-main { padding-top: 5px; padding-bottom: 5px; }
.wraper-header.shrink > .header-main .navbar-brand .logo-text { color: #A9070B; }
.wraper-header.shrink > .header-main .main-navbar-content > ul > li { margin-left: 25px; }
.wraper-header.shrink > .header-main .main-navbar-content > ul > li .nav-link { color: #000; }
.wraper-header.shrink > .header-main .main-navbar-content > ul > li .nav-link:before { background-color: #000; }
.wraper-header.others-header { background-color: #fff; box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1); background-image: none; }
.wraper-header.others-header > .header-main { padding-top: 5px; padding-bottom: 5px; }
.wraper-header.others-header > .header-main .navbar-brand .logo-text { color: #A9070B; }
.wraper-header.others-header > .header-main .main-navbar-content > ul > li { margin-left: 25px; }
.wraper-header.others-header > .header-main .main-navbar-content > ul > li .nav-link { color: #000; }
.wraper-header.others-header > .header-main .main-navbar-content > ul > li .nav-link:before { background-color: #000; }

.trigger-menu-wrapper { display: none; }

.navbar-brand { display: flex; align-items: center; }
.navbar-brand .logo-img { border-radius: 30px; width: 105px; }
.navbar-brand .logo-text { font-family: "Roboto", sans-serif; font-weight: 800; font-size: 18px; padding-left: 10px; color: #fff; }

.main-navbar-content > ul > li { margin-left: 25px; }
.main-navbar-content > ul > li.current-menu-item .nav-link:before { animation-name: under-line; animation-duration: 0.5s; opacity: 1; }
.main-navbar-content > ul > li .nav-link { font-size: 14px; line-height: 1.2; color: #fff; position: relative; cursor: pointer; -webkit-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }
@media screen and (min-width: 768px) { .main-navbar-content > ul > li .nav-link { font-size: 14px; line-height: 1.2; } }
@media screen and (min-width: 992px) { .main-navbar-content > ul > li .nav-link { font-size: 14px; line-height: 1.2; } }
@media screen and (min-width: 1200px) { .main-navbar-content > ul > li .nav-link { font-size: 16px; line-height: 1.3; } }
@media screen and (min-width: 1400px) { .main-navbar-content > ul > li .nav-link { font-size: 16px; line-height: 1.3; } }
@media screen and (min-width: 1800px) { .main-navbar-content > ul > li .nav-link { font-size: 18px; line-height: 1.3; } }
.main-navbar-content > ul > li .nav-link:before { content: ''; position: absolute; bottom: -2px; left: 50%; transform: translateX(-50%); width: 25%; height: 1.5px; background-color: #fff; opacity: 0; }
@media only screen and (max-width: 992px) { .main-navbar-content > ul > li .nav-link:before { background-color: #000; } }
.main-navbar-content > ul > li .nav-link:hover:before { animation-name: under-line; animation-duration: 0.5s; opacity: 1; }
.main-navbar-content > ul > li.li-has-children { position: relative; }
.main-navbar-content > ul > li.li-has-children > a { position: relative; padding-right: 16px !important; }
.main-navbar-content > ul > li.li-has-children > a:after { content: "\f107"; font-family: 'Line Awesome Free'; font-weight: 900; position: absolute; right: 0; top: 12px; font-size: 12px; }
.main-navbar-content > ul > li.li-has-children .sub-menu { position: absolute; list-style: none; top: calc(100% + 20px); left: 0; z-index: 1; width: 190px; padding-top: 5px; padding-bottom: 6px; background-color: #fff; border: none; border-radius: 0; box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05); text-align: left; opacity: 0; visibility: hidden; -webkit-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }
.main-navbar-content > ul > li.li-has-children .sub-menu li { position: relative; }
.main-navbar-content > ul > li.li-has-children .sub-menu li a { position: relative; display: block; padding: 10px 15px 10px 15px; font-weight: 400; font-size: 13px; color: #000; line-height: 1.4; -webkit-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }
.main-navbar-content > ul > li.li-has-children .sub-menu li a:hover { background-color: rgba(0, 0, 0, 0.05); color: #A9070B; }
.main-navbar-content > ul > li.li-has-children .sub-menu.open { top: calc(100% + 10px); opacity: 1; visibility: visible; height: auto; }
@media only screen and (max-width: 991px) { .main-navbar-content > ul > li.li-has-children .sub-menu { position: relative; height: 0; padding: 0; } }
@media only screen and (min-width: 992px) { .main-navbar-content > ul > li.li-has-children:hover > .sub-menu { top: calc(100% + 10px); opacity: 1; visibility: visible; } }

@-webkit-keyframes under-line { 0% { width: 0; }
  100% { width: 25%; } }
@keyframes under-line { 0% { width: 0; }
  100% { width: 25%; } }
.wraper-header > .header-main .btn.login-btn { padding: 10px 10px; }
@media only screen and (max-width: 991px) { .wraper-header > .header-main .btn.login-btn { width: 100%; margin-bottom: 10px; } }
.wraper-header > .header-main .btn.signup-btn { padding: 10px 10px; }
@media only screen and (max-width: 991px) { .wraper-header > .header-main .btn.signup-btn { width: 100%; } }
.wraper-header.shrink > .header-main .btn.login-btn { color: #000; border: 1px solid #000; transition: all ease-in-out 300ms; -webkit-transition: all ease-in-out 300ms; -moz-transition: all ease-in-out 300ms; -ms-transition: all ease-in-out 300ms; padding: 10px 10px; }
.wraper-header.shrink > .header-main .btn.login-btn:hover { background: linear-gradient(90deg, #F34444 0%, #A52C24 100%), #A52C24; color: #fff; border: 1px solid transparent; }
.wraper-header.shrink > .header-main .btn.signup-btn { background: linear-gradient(90deg, #F34444 0%, #A52C24 100%), #A52C24; color: #fff; border: 1px solid transparent; padding: 10px 10px; }
.wraper-header.shrink > .header-main .btn.signup-btn:hover { color: #000; border: 1px solid #000; background: transparent; }
.wraper-header.others-header > .header-main .btn.login-btn { color: #000; border: 1px solid #000; transition: all ease-in-out 300ms; -webkit-transition: all ease-in-out 300ms; -moz-transition: all ease-in-out 300ms; -ms-transition: all ease-in-out 300ms; }
.wraper-header.others-header > .header-main .btn.login-btn:hover { background: linear-gradient(90deg, #F34444 0%, #A52C24 100%), #A52C24; color: #fff; border: 1px solid transparent; }
.wraper-header.others-header > .header-main .btn.signup-btn { background: linear-gradient(90deg, #F34444 0%, #A52C24 100%), #A52C24; color: #fff; border: 1px solid transparent; }
.wraper-header.others-header > .header-main .btn.signup-btn:hover { color: #000; border: 1px solid #000; background: transparent; }

/* ====================== */
/* END OF HEADER */
/* ====================== */
/* ========================== */
/* START OF RESPONSIVE CSS */
/* ========================== */
@media only screen and (max-width: 1199px) { .main-navbar-content > ul > li { margin-left: 15px; }
  .wraper-header.shrink > .header-main .main-navbar-content > ul > li { margin-left: 15px; }
  .wraper-header.others-header > .header-main .main-navbar-content > ul > li { margin-left: 15px; } }
@media only screen and (max-width: 992px) { body.menu-open { overflow: hidden; }
  .wraper-header .header-main, .wraper-header.shrink .header-main, .wraper-header.others-header .header-main { z-index: 9; display: block; padding-top: 0; padding-bottom: 0; }
  .menu-open .wraper-header, .menu-open .wraper-header.shrink { background: #eeeeee; position: fixed; height: 100%; }
  .wraper-header .header-main .navbar-brand { margin-right: 5px; }
  .wraper-header .header-main .navbar-brand .logo-text { color: #fff; }
  .wraper-header .header-main .navbar { padding-left: 0; padding-right: 0; }
  .wraper-header.shrink .navbar-brand .logo-text { color: #A9070B; }
  .wraper-header.shrink > .header-main .main-navbar-content > ul > li { margin-left: 0; }
  .wraper-header.others-header .navbar-brand .logo-text { color: #A9070B; }
  .wraper-header.others-header > .header-main .main-navbar-content > ul > li { margin-left: 0; }
  .wraper-header .navbar .main-navbar-content { margin: 0 auto !important; }
  .menu-open .wraper-header .navbar-brand .logo-text { color: #A9070B; }
  .main-navbar-content > ul > li { margin-left: 0; margin-bottom: 10px; }
  .main-navbar-content > ul > li .nav-link { color: #000; font-size: 18px; display: block; text-align: center; }
  .trigger-menu-wrapper { display: block; }
  .trigger-menu { position: relative; z-index: 99; border: 0; height: 50px; display: flex; justify-content: center; align-items: center; width: 50px; border-radius: 50%; padding: 0; background-color: #eeeeee; outline: none !important; }
  .trigger-menu:focus { outline: none; }
  .trigger-menu #nav-icon3 { width: 24px; height: 22px; display: inline-block; position: relative; transform: rotate(0deg); transition: .5s ease-in-out; cursor: pointer; top: 2px; }
  .trigger-menu #nav-icon3 span { display: block; position: absolute; height: 2px; width: 100%; background: #000; border-radius: 9px; opacity: 1; left: 0; transform: rotate(0deg); transition: .25s ease-in-out; }
  .trigger-menu #nav-icon3 span:nth-child(1) { top: 0; }
  .trigger-menu #nav-icon3 span:nth-child(2) { top: 8px; }
  .trigger-menu #nav-icon3 span:nth-child(4) { top: 16px; height: 2px; }
  .menu-open .trigger-menu { background-color: transparent; }
  .menu-open .trigger-menu #nav-icon3 span { background-color: #1c252a; }
  .menu-open .trigger-menu #nav-icon3 span:nth-child(1) { top: 9px; width: 0; left: 50%; }
  .menu-open .trigger-menu #nav-icon3 span:nth-child(2) { transform: rotate(45deg); }
  .menu-open .trigger-menu #nav-icon3 span:nth-child(3) { transform: rotate(-45deg); }
  .menu-open .trigger-menu #nav-icon3 span:nth-child(4) { top: 9px; width: 0; left: 50%; }
  .menu-open .navbar-collapse { display: flex; margin-top: 10px; height: calc(100vh - 100px); justify-content: center; overflow-y: auto; }
  #nav-icon3 span:nth-child(3) { top: 8px; }
  .wraper-header > .header-main .btn.login-btn { color: #000; border: 1px solid #000; transition: all ease-in-out 300ms; -webkit-transition: all ease-in-out 300ms; -moz-transition: all ease-in-out 300ms; -ms-transition: all ease-in-out 300ms; }
  .wraper-header > .header-main .btn.login-btn:hover { background: linear-gradient(90deg, #F34444 0%, #A52C24 100%), #A52C24; color: #fff; border: 1px solid transparent; }
  .wraper-header > .header-main .btn.signup-btn { background: linear-gradient(90deg, #F34444 0%, #A52C24 100%), #A52C24; color: #fff; border: 1px solid transparent; }
  .wraper-header > .header-main .btn.signup-btn:hover { color: #000; border: 1px solid #000; background: transparent; } }
/* ========================== */